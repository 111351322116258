import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AnalysisDelete from './AnalysisDelete';
import AnalysisName from './AnalysisName';
import AnalysisFen from './AnalysisFen';
import GearIcon from '../icons/GearIcon';
import { updateCurrentUser } from '../actions/currentUser';
import { isEmpty } from '../util/utils';

class AnalysisSettings extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.requireLogin = this.requireLogin.bind(this);
  }

  requireLogin() {
    const { currentUser } = this.props;
    if (isEmpty(currentUser)) alert('Must be logged in for that');
  }

  handleChange(event) {
    event.preventDefault();

    const { dispatch, currentUser } = this.props;
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    dispatch(updateCurrentUser(currentUser.id, { [name]: value }));
  }

  render() {
    const { currentUser, position, cgEventChange } = this.props;
    let dataTarget = '#settingsModal';

    if (isEmpty(currentUser)) {
      dataTarget = '';
    }

    return (
      <>
        <button
          type="button"
          className="btn btn-outline-secondary btn-block"
          data-bs-toggle="modal"
          data-bs-target={dataTarget}
          onClick={this.requireLogin}
        >
          <GearIcon />
          Settings
        </button>
        <div id="settingsModal" className="modal fade" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Analysis Settings</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <AnalysisName key={position && position.id} position={position} />
                <AnalysisFen cgEventChange={cgEventChange} />
                <div className="form-check">
                  <label className="form-check-label" htmlFor="move_automatically_checkbox">
                    <input
                      id="move_automatically_checkbox"
                      name="move_automatically"
                      className="form-check-input"
                      type="checkbox"
                      onChange={this.handleChange}
                      checked={currentUser.move_automatically}
                    />
                    Move Automatically
                  </label>
                </div>
                <AnalysisDelete position={position} />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

AnalysisSettings.propTypes = {
  dispatch: PropTypes.func,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    move_automatically: PropTypes.bool,
  }),
  cgEventChange: PropTypes.func.isRequired,
  position: PropTypes.shape({
    id: PropTypes.string,
  }),
};

AnalysisSettings.defaultProps = {
  dispatch: () => {},
  currentUser: {},
  position: null,
};

function mapStateToProps(state) {
  const { currentUser } = state;

  return { currentUser };
}

export default connect(mapStateToProps)(AnalysisSettings);
