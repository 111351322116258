import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* global chessground */

import { isEmpty } from '../util/utils';

class AnalysisArrows extends React.Component {
  constructor(props) {
    super(props);
    this.drawArrows = this.drawArrows.bind(this);
  }

  componentDidUpdate() {
    const { moves } = this.props;

    if(isEmpty(moves)) return;
    this.drawArrows();
  }

  drawArrows() {
    const { moves } = this.props;
    if(isEmpty(moves)) return;

    let orig, dest, text, color;
    const shapeSet = [];
    const shapeColors = ['green', 'blue', 'paleGreen', 'paleBlue'];
    const numArrows = Math.min(...[moves.length, shapeColors.length]);
    for (let i = 0; i < numArrows; i++) {
      [orig, dest] = moves[i].match(/.{1,2}/g);
      text = (i + 1).toString();
      color = shapeColors[i];
      shapeSet.push({ orig, dest, brush: shapeColors[i], label: { fill: color, text } });
    }

    chessground.setShapes(shapeSet);
  }

  render() {
    return null;
  }
}

AnalysisArrows.propTypes = {
  moves: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  const { moves } = state.analysisArrows;

  return { moves };
}

export default connect(mapStateToProps)(AnalysisArrows);
