import React from 'react';
import PropTypes from 'prop-types';
/* global chessground */

class AnalysisClearButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { cgEventChange } = this.props;

    chessground.set({ fen: '8/8/8/8/8/8/8/8' });
    cgEventChange();
  }

  render() {
    return (
      <button type="button" className="btn btn-outline-warning me-1" onClick={this.handleClick}>
        Clear
      </button>
    )
  }

}

AnalysisClearButton.propTypes = {
  cgEventChange: PropTypes.func.isRequired,
};

export default AnalysisClearButton
