import { ANALYSIS_ACTIONS } from '../../constants/actionTypes';
const { EDIT_NAME, CHANGE_NAME_VALUE } = ANALYSIS_ACTIONS;

const initialState = {
  edit: false,
  newValue: null,
};

export function analysisNameInputReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_NAME:
      return {
        ...state,
        edit: !state.edit,
      };
    case CHANGE_NAME_VALUE:
      return {
        ...state,
        newValue: action.newValue,
      };
    default:
      return state;
  }
}
