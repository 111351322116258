import { ANALYSIS_ACTIONS } from '../../constants/actionTypes';

const { RECEIVE_ANALYSIS_MOVE, RESET_ANALYSIS_MOVER, RECEIVE_STOCKFISH_MOVE } = ANALYSIS_ACTIONS;

const initialState = {
  fen: '',
  pendingMoves: [],
  source: '',
}

export function analysisMoverReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ANALYSIS_MOVER:
      return initialState;
    case RECEIVE_ANALYSIS_MOVE: {
      const { fen, sequence } = action; // Scoped to this block

      return { ...state, fen, source: 'database', pendingMoves: [sequence[0]] };
    }
    case RECEIVE_STOCKFISH_MOVE: {
      const { fen, sequence } = action; // Scoped to this block

      return { ...state, fen, source: 'stockfish', pendingMoves: [sequence[0]] };
    }
    default:
      return state;
  }
}
