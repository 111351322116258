import React from 'react';

import { EventApi } from '../api/eventApi';

class AnalysisCommentsButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.eventApi = new EventApi();

  }

  handleClick() {
    this.eventApi.create("placeholder data", 'comments_click');
  }

  render() {
    return (
      <>
        <button
          className="btn btn-sm btn-link"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasComments"
          aria-controls="offcanvasBottom"
          onClick={this.handleClick}
        >
          Comments
        </button>
        <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="offcanvasComments" aria-labelledby="offcanvasBottomLabel">
          <div className="offcanvas-body small">
          <li className="list-group-item">
            We are still working on this feature
          </li>
          </div>
        </div>
      </>

    );
  }
}

AnalysisCommentsButton.propTypes = {
};

export default AnalysisCommentsButton;
