import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* global */

import AnalysisFlipButton from './AnalysisFlipButton';
import AnalysisClearButton from './AnalysisClearButton';
import AnalysisResetButton from './AnalysisResetButton';
import AnalysisSettings from './AnalysisSettings';
import AnalysisCastlingCheckbox from './AnalysisCastlingCheckbox';
import AnalysisCommentsButton from './AnalysisCommentsButton';

class AnalysisControls extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { cgEventChange } = this.props;

    return (
      <div className="card"  style={{ width: '24rem' }}>
        <div className="card-body">
          <div className="text-black-50 bg-white p-2">
            White Castling&nbsp;
            <AnalysisCastlingCheckbox value="K" label="king" />
            <AnalysisCastlingCheckbox value="Q" label="queen" />
          </div>
          <div className="text-white-50 bg-dark p-2">
            Black Castling&nbsp;
            <AnalysisCastlingCheckbox value="k" label="king" />
            <AnalysisCastlingCheckbox value="q" label="queen" />
          </div>
          <div className="p-2">
            <AnalysisFlipButton />
            <AnalysisResetButton cgEventChange={cgEventChange} />
            <AnalysisClearButton cgEventChange={cgEventChange} />
            <AnalysisSettings cgEventChange={cgEventChange} />
          </div>
          <div className="p-2">
            <AnalysisCommentsButton />
          </div>
        </div>
      </div>
    );
  }
}

AnalysisControls.propTypes = {
  dispatch: PropTypes.func.isRequired, // Function to dispatch Redux actions
  cgEventChange: PropTypes.func.isRequired,
  position: PropTypes.shape({
    moves: PropTypes.arrayOf(PropTypes.string).isRequired, // List of moves in the current position
  }).isRequired,
  flipBtn: PropTypes.node, // Optional React node for flip button
  resetBtn: PropTypes.node, // Optional React node for reset button
  clearBtn: PropTypes.node, // Optional React node for clear button
  children: PropTypes.node, // Optional children elements to be rendered within the component
  Settings: PropTypes.node, // Optional React node for settings UI
};

function mapStateToProps(state) {
  const { castling, currentUser, position } = state;

  return {
    castling,
    currentUser,
    position,
  };
}

export default connect(mapStateToProps)(AnalysisControls);
