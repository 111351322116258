import { Chess } from 'chess.js';

export const STARTING_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
export const MOVE_TIME_IN_MILLISECONDS = 3000;
export const STOCKFISH_VERSION = 'stockfishjs 10';
export const NUM_MOVES_TO_SHOW = 4;

export const CHESSJS = new Chess();
window.CHESSJS = CHESSJS; // needs to be global for rspec tests

export const CHESSGROUND_CONFIG = {
  animation: {
    duration: 500,
  },
  fen: STARTING_FEN,
  draggable: {
    showGhost: true,
  },
  //events: {
  //  change: function(orig, dest, metadata) {
  //    console.log('change');
  //  },
  //  move: function(orig, dest, metadata) {
  //    console.log('move');
  //  },
  //  dropNewPiece: function(orig, dest, metadata) {
  //    console.log('dropNewPiece');
  //  },
  //  select: function(orig, dest, metadata) {
  //    console.log('select');
  //  },
  //  insert: function(orig, dest, metadata) {
  //    console.log('insert');
  //  },
  //},
};
