import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FenApi } from '../api/fenApi';

class AnalysisUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.fenApi = new FenApi();
  }

  componentDidUpdate() {
    const { fen, score, sequence } = this.props;

    this.fenApi.update({fen, score, sequence})
  }

  render() {
    return (null);
  }
}

AnalysisUpdate.propTypes = {
  dispatch: PropTypes.func.isRequired, // Function to dispatch actions
  fen: PropTypes.string.isRequired, // FEN string representing the current board state
  sequence: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of moves in sequence (e.g., SAN or UCI notation)
  score: PropTypes.string.isRequired, // Evaluation score of the position
};

function mapStateToProps(state) {
  const { fen, score, sequence } = state.analysisUpdate

  return { fen, score, sequence };
}

export default connect(mapStateToProps)(AnalysisUpdate);
