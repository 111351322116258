import React, { Component } from 'react';
import FlagIcon from '../icons/FlagIcon';

export class ReportProblemFlag extends Component {
  render() {
    return (
      <button type="button" id="reportProblem" className="float-right btn btn-link" data-bs-toggle="modal" data-bs-target="#feedbackModal" title="Report a problem">
        <FlagIcon />
      </button>
    );
  }
}
export default ReportProblemFlag;
