import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable-next-line no-unused-vars */
import { DragDropTouch } from '../src/drag_drop_touch';

import bK from 'images/pieces/bK.svg';
import bQ from 'images/pieces/bQ.svg';
import bR from 'images/pieces/bR.svg';
import bB from 'images/pieces/bB.svg';
import bN from 'images/pieces/bN.svg';
import bP from 'images/pieces/bP.svg';

import wK from 'images/pieces/wK.svg';
import wQ from 'images/pieces/wQ.svg';
import wR from 'images/pieces/wR.svg';
import wB from 'images/pieces/wB.svg';
import wN from 'images/pieces/wN.svg';
import wP from 'images/pieces/wP.svg';

const IMAGES_MAP = {
  'white_king': wK,
  'white_queen': wQ,
  'white_rook': wR,
  'white_bishop': wB,
  'white_knight': wN,
  'white_pawn': wP,
  'black_king': bK,
  'black_queen': bQ,
  'black_rook': bR,
  'black_bishop': bB,
  'black_knight': bN,
  'black_pawn': bP,
};

class AnalysisSparePiece extends React.Component {
  static handleDrag(event) {
    event.dataTransfer.setData('text', event.target.id);
  }

  // TODO: remove harcoded width/height
  render() {
    const { color, piece } = this.props;

    return (
      <img
        className="spare"
        id={color + '_' + piece}
        alt={color + '_' + piece}
        data-color={color}
        data-piece={piece}
        width="50"
        height="50"
        onDragStart={AnalysisSparePiece.handleDrag}
        draggable="true"
        src={IMAGES_MAP[color + '_' + piece]}
      />
    );
  }
}

AnalysisSparePiece.propTypes = {
  color: PropTypes.string,
  piece: PropTypes.string,
};

AnalysisSparePiece.defaultProps = {
  color: '',
  piece: '',
};

export default AnalysisSparePiece;
