import { ANALYSIS_ACTIONS } from '../../constants/actionTypes';
const { RECEIVE_CASTLING } = ANALYSIS_ACTIONS;

const initialState = {
  K: true,
  Q: true,
  k: true,
  q: true,
}

export function analysisCastlingReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_CASTLING:
      return action.castling;
    default:
      return state;
  }
}
