import { UPDATE_NAV } from '../constants/actionTypes';

const initialState = {};

export function navButtonsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_NAV:
      return {
        ...state,
        current: action.current,
      };
    default:
      return state;
  }
}

export default navButtonsReducer;
