import React from 'react';
import PropTypes from 'prop-types';

import { EventApi } from '../api/eventApi';
import { isEmpty } from '../util/utils';

class AnalysisOpeningsButton extends React.Component {
  constructor(props) {
    super(props);
    this.openingListItems = this.openingListItems.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.eventApi = new EventApi();

  }

  handleClick() {
    const { fen } = this.props;

    this.eventApi.create(fen, 'related_openings_click');
  }

  openingListItems() {
    const { openings } = this.props;

    return (
      <ul className="list-group">
         {Object.entries(openings).map(([key, value]) => (
          <li key={key} className="list-group-item">
            <a href={`/openings/${key}`} target="_blank" rel="noreferrer">{value}</a>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { openings } = this.props;

    if(isEmpty(openings)) return (null);

    return (
      <>
        <button
          className="btn btn-sm btn-link"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasBottom"
          aria-controls="offcanvasBottom"
          onClick={this.handleClick}
        >
          Related openings
        </button>
        <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
          <div className="offcanvas-body small">
            {this.openingListItems()}
          </div>
        </div>
      </>

    );
  }
}

AnalysisOpeningsButton.propTypes = {
  fen: PropTypes.string.isRequired,
  openings: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default AnalysisOpeningsButton;
