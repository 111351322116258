import { isCastlingAvailable } from '../util/castlingHelper';
import { isEmpty } from '../util/utils';

import { ANALYSIS_ACTIONS } from '../constants/actionTypes';
const { RECEIVE_CASTLING } = ANALYSIS_ACTIONS;

export function updateCastling(chessground, castling) {
  if(isEmpty(castling)) {
    castling = {
      'K': isCastlingAvailable(chessground.state.pieces, 'K'),
      'Q': isCastlingAvailable(chessground.state.pieces, 'Q'),
      'k': isCastlingAvailable(chessground.state.pieces, 'k'),
      'q': isCastlingAvailable(chessground.state.pieces, 'q'),
    };
  }
  return {
    type: RECEIVE_CASTLING,
    castling,
  };
}
