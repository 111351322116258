export function getCastlingString(castling) {
  let castlingString = ""

  for (let [key, value] of Object.entries(castling)) {
    if(value === true) castlingString += key;
  }

  return castlingString || "-"
}

export function isCastlingAvailable(pieces, value) {
  const isPiece = (pos, role, color) => {
    const piece = pieces.get(pos);
    return piece !== undefined && piece.role === role && piece.color === color;
  };

  switch (value) {
    case 'K': return isPiece('e1', 'king', 'white') && isPiece('h1', 'rook', 'white');
    case 'Q': return isPiece('e1', 'king', 'white') && isPiece('a1', 'rook', 'white');
    case 'k': return isPiece('e8', 'king', 'black') && isPiece('h8', 'rook', 'black');
    case 'q': return isPiece('e8', 'king', 'black') && isPiece('a8', 'rook', 'black');
    default:  return false;
  }
}
