import { ANALYSIS_ACTIONS } from '../constants/actionTypes';
const { CHANGE_FEN_VALUE, EDIT_FIELD } = ANALYSIS_ACTIONS;

export function toggleEdit() {
  return { type: EDIT_FIELD };
}

export function updateFenInput(json) {
  return {
    type: CHANGE_FEN_VALUE,
    newValue: json.newValue,
  };
}
