// nav buttons
export const UPDATE_NAV = 'UPDATE_NAV'

export const REQUEST_STOCKFISH_MOVE = 'REQUEST_STOCKFISH_MOVE'; // shared
export const RECEIVE_STOCKFISH_MOVE = 'RECEIVE_STOCKFISH_MOVE'; // shared

export const ANALYSIS_ACTIONS = {
  EDIT_FIELD: 'EDIT_FIELD',
  CHANGE_FEN_VALUE: 'CHANGE_FEN_VALUE',
  CHANGE_NAME_VALUE: 'CHANGE_NAME_VALUE',
  EDIT_NAME: 'EDIT_NAME',
  RESET_ANALYSIS: 'RESET_ANALYSIS',
  REQUEST_ANALYSIS: 'REQUEST_ANALYSIS',
  RECEIVE_ANALYSIS: 'RECEIVE_ANALYSIS',
  RECEIVE_ANALYSIS_MOVE: 'RECEIVE_ANALYSIS_MOVE',
  RECEIVE_CASTLING: 'RECEIVE_CASTLING',
  RECEIVE_PENDING_MOVES: 'RECEIVE_PENDING_MOVES',
  RECEIVE_POSITION: 'RECEIVE_POSITION',
  RECEIVE_USER_MOVES: 'RECEIVE_USER_MOVES',
  RECEIVE_ANALYSIS_MESSAGE: 'RECEIVE_ANALYSIS_MESSAGE',
  RESET_ANALYSIS_HEADER: 'RESET_ANALYSIS_HEADER',
  RESET_ANALYSIS_MOVER: 'RESET_ANALYSIS_MOVER',
  RECEIVE_STOCKFISH_MOVE,
};

export const COMPUTER_GAME_ACTIONS = {
  RECEIVE_COMPUTER_GAME: 'RECEIVE_COMPUTER_GAME',
  RESET_COMPUTER_GAME_MOVER: 'RESET_COMPUTER_GAME_MOVER',
  RECEIVE_STOCKFISH_MOVE,
  REQUEST_STOCKFISH_MOVE,
};

export const GAME_ACTIONS = {
  RECEIVE_ANALYSIS: 'RECEIVE_ANALYSIS',
  RECEIVE_GAME: 'RECEIVE_GAME',
  RECEIVE_STOCKFISH_MOVE,
};

export const OPENING_ACTIONS = {
  RECEIVE_OPENING: 'RECEIVE_OPENING',
  COPIED_OPENING: 'COPIED_OPENING',
};

export const PUZZLE_ACTIONS = {
  RECEIVE_HINT: 'RECEIVE_HINT',
  RECEIVE_TACTIC: 'RECEIVE_TACTIC',
  CREATED_USER_TACTIC: 'CREATED_USER_TACTIC',
  RECEIVE_LIKES: 'RECEIVE_LIKES',
  DELETE_LIKE: 'DELETE_LIKE',
  UPDATE_PUZZLE_STATUS: 'UPDATE_PUZZLE_STATUS',
  ADMIN_RECEIVE_FEN: 'ADMIN_RECEIVE_FEN',
};
