import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* global chessground */

import { CHESSJS } from '../constants/constants';
import { updateNavButtons } from '../actions/navButtons';
import { createOrUpdatePosition } from '../actions/position';
import { updateCastling } from '../actions/castling';
import { isEmpty } from '../util/utils';
import { makeMove } from '../util/chessHelper';
import { ANALYSIS_ACTIONS } from '../constants/actionTypes';
const { RESET_ANALYSIS_MOVER } = ANALYSIS_ACTIONS;

class AnalysisMover extends React.Component {
  constructor(props) {
    super(props);
  }

  // used for moving automatically if the user has the setting enabled
  componentDidUpdate() {
    const { currentUser, dispatch, navButtons, pendingMoves, position } = this.props;

    if (isEmpty(pendingMoves) || !currentUser.move_automatically) return;
    makeMove(pendingMoves[0], CHESSJS, chessground);

    const newFen = CHESSJS.fen().split(' ')[0];
    const moves = position.moves.slice(0, navButtons.current + 1)
    moves.push(newFen)

    dispatch(createOrUpdatePosition(position, { moves }));
    dispatch(updateCastling(chessground));
    dispatch(updateNavButtons(moves.length - 1));
    dispatch({type: RESET_ANALYSIS_MOVER});
  }

  render() {
    return null;
  }
}

AnalysisMover.propTypes = {
  dispatch: PropTypes.func.isRequired, // Function to dispatch actions
  fen: PropTypes.string.isRequired, // FEN string representing the current board state
  pendingMoves: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of pending moves (e.g., UCI notation or similar)
  position: PropTypes.shape({
    moves: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of move strings in the position
  }).isRequired, // Object representing the current position
  navButtons: PropTypes.shape({
    current: PropTypes.number
  }).isRequired, // Object representing the current position
  source: PropTypes.string.isRequired, // Source of the move (e.g., 'header_click')
  currentUser: PropTypes.shape({
    move_automatically: PropTypes.bool.isRequired, // Indicates if the user prefers automatic moves
  }).isRequired, // Object representing the current user
};

function mapStateToProps(state) {
  const { currentUser, navButtons, position } = state;
  const { fen, pendingMoves, source } = state.analysisMover;

  return { currentUser, fen, navButtons, pendingMoves, source, position };
}

export default connect(mapStateToProps)(AnalysisMover);
