import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* global chessground */

import { ANALYSIS_ACTIONS } from '../constants/actionTypes';
import { CHESSJS } from '../constants/constants';
import { Uci } from '../util/uci';
import { getCastlingString } from '../util/castlingHelper';
import { FenApi } from '../api/fenApi';

const { REQUEST_ANALYSIS } = ANALYSIS_ACTIONS;

class AnalysisCalculateButton extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;

    this.handleCalculate = this.handleCalculate.bind(this);
    this.uci = new Uci(dispatch);
    this.fenApi = new FenApi();
  }

  handleCalculate(event) {
    const color = event.target.value;
    const { castling, dispatch } = this.props;
    const fen_string = [chessground.getFen(), color, getCastlingString(castling), '-', '0', '1'].join(' ');
    CHESSJS.load(fen_string);
    dispatch({ type: REQUEST_ANALYSIS }) // tells AnalysisHeader to show 'Calculating, please wait'
    dispatch(this.fenApi.create(fen_string, this.uci));
  }

  render() {
    const { value, analysisCalculateButtons } = this.props;
    const btnClass = value === "b" ? "btn-dark" : "btn-outline-secondary";
    const btnText = value === "b" ? "Black to Move" : "White to Move";
    const disabled = analysisCalculateButtons.status === "pending" ? true : false;

    return(
      <button type="button" className={"btn " + btnClass} onClick={this.handleCalculate} value={value} disabled={disabled}>
        {disabled && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
        {btnText}
      </button>
    )
  }
}

AnalysisCalculateButton.propTypes = {
  castling: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  analysisCalculateButtons: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  const { castling, analysisCalculateButtons } = state;

  return { castling, analysisCalculateButtons };
}

export default connect(mapStateToProps)(AnalysisCalculateButton);
