import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* global chessground */

import { getPosition, createOrUpdatePosition } from '../actions/position';
import { updateCastling } from '../actions/castling';
import { updateNavButtons } from '../actions/navButtons';
import AnalysisHeader from './AnalysisHeader';
import AnalysisBoard from './AnalysisBoard';
import AnalysisControls from './AnalysisControls';
import AnalysisArrows from './AnalysisArrows';
import AnalysisMover from './AnalysisMover';
import AnalysisUpdate from './AnalysisUpdate';
import NavButtons from '../shared/NavButtons';
import AnalysisCalculateButton from './AnalysisCalculateButton';


import { STARTING_FEN } from '../constants/constants';
import { ANALYSIS_ACTIONS } from '../constants/actionTypes';
const { RESET_ANALYSIS_HEADER } = ANALYSIS_ACTIONS;

class Analysis extends React.Component {
  constructor(props) {
    super(props);
    this.cgEventChange = this.cgEventChange.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  // supports edit and new
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getPosition())
      .then((response) => {
        const { position, orientation } = response;
        const moveNum = position.moves.length - 1;
        chessground.set({ fen: position.moves[moveNum], orientation });
        dispatch(updateCastling(chessground));
        dispatch(updateNavButtons(moveNum));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  cgEventChange() {
    const { dispatch, navButtons, position } = this.props;

    const json = { moves: position.moves.slice(0, navButtons.current + 1).concat([chessground.getFen()]) }
    dispatch(createOrUpdatePosition(position, json))
    dispatch({type: RESET_ANALYSIS_HEADER});
    dispatch(updateCastling(chessground));
    dispatch(updateNavButtons(json.moves.length - 1));
  }

  goTo(moveNum) {
    const { position, dispatch } = this.props;

    let fen = position.moves[moveNum];
    if(moveNum == -1) fen = STARTING_FEN;

    chessground.set({ fen });
    dispatch(updateNavButtons(moveNum)); // refactor to use reducer
  }

  render() {
    const { position } = this.props;

    return (
      <div>
        <AnalysisHeader />
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 mr-3">
            <AnalysisBoard cgEventChange={this.cgEventChange} />
            <div className="row p-2">
              <div className="btn-group" role="group">
                <AnalysisCalculateButton value="w" />&nbsp;
                <AnalysisCalculateButton value="b" />
              </div>
            </div>
            <div className="row">
              <NavButtons last={position.moves.length - 1} goTo={this.goTo} />
            </div>
          </div>
          <div className="col-lg-4 col-md-9 col-sm-9">
            <AnalysisControls cgEventChange={this.cgEventChange} />
          </div>
        </div>
        <AnalysisMover />
        <AnalysisArrows />
        <AnalysisUpdate />
      </div>
    );
  }
}

Analysis.propTypes = {
  dispatch: PropTypes.func.isRequired, // Function to dispatch Redux actions
  position: PropTypes.shape({
    moves: PropTypes.arrayOf(PropTypes.string).isRequired, // List of moves in the current position
  }).isRequired,
  navButtons: PropTypes.shape({
    current: PropTypes.number
  }).isRequired, // Object representing the current position
};

function mapStateToProps(state) {
  const { analysis, navButtons, position } = state;

  return { analysis, position, navButtons };
}

export default connect(mapStateToProps)(Analysis);
