import { StockfishWorker } from '../util/stockfishWorker';
import { MOVE_TIME_IN_MILLISECONDS } from '../constants/constants';
import { COMPUTER_GAME_ACTIONS } from '../constants/actionTypes';

const { REQUEST_STOCKFISH_MOVE } = COMPUTER_GAME_ACTIONS;

export class Uci {
  constructor(dispatch) {
    this.dispatch = dispatch;
    this.stockfish = new StockfishWorker(dispatch);
  }

  analyze_for_time(fen, time_in_milliseconds = MOVE_TIME_IN_MILLISECONDS) {
    this.stockfish.fen = fen;
    this.stockfish.postMessage("setoption name Hash value 256");
    this.stockfish.postMessage("position fen " + fen);
    this.stockfish.postMessage("go movetime " + time_in_milliseconds);
  }

  // this.stockfish.postMessage("setoption name UCI_LimitStrength value true"); UCI_LimitStrengh and UCI_Elo options not working
  // this.stockfish.postMessage("setoption name UCI_Elo value 1200"),
  analyze_for_level(fen, level) {
    this.dispatch({ type: REQUEST_STOCKFISH_MOVE });

    this.stockfish.fen = fen;
    this.stockfish.postMessage("setoption name Hash value 256");
    this.stockfish.postMessage("setoption name Skill Level value " + level * 2);
    this.stockfish.postMessage("position fen " + fen);
    this.stockfish.postMessage("go movetime " + MOVE_TIME_IN_MILLISECONDS);
  }
}
