import axiosCSRF from '../util/axiosCSRF';

export class EventApi {
  create(data_string, action) {
    const data = {
      event: {
        action,
        metadata: { data: data_string },
      }
    };

    axiosCSRF.post('/api/v2/events.json', data)
    /* eslint-disable-next-line no-unused-vars */
    .then(_response => {
      //console.log('Analysis saved:', _response.data);
    }).catch(error => {
      console.error('Error:', error);
    });
  }
}