import { ANALYSIS_ACTIONS } from '../../constants/actionTypes';

const { REQUEST_ANALYSIS, RECEIVE_ANALYSIS_MOVE, RECEIVE_ANALYSIS_MESSAGE, RECEIVE_STOCKFISH_MOVE } = ANALYSIS_ACTIONS;

const initialState = {
  status: 'idle', // pending, idle, error
}

/* buttons are disabled when requesting a move,
   they are renabled after receiving either a move from the db or stockfishjs,
   or when the position is determined to be checkmate, stalemate, or invalid
*/
export function analysisCalculateButtonsReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ANALYSIS:
      return { ...state, status: 'pending'};
    case RECEIVE_ANALYSIS_MOVE: // stacked received analysis from the db
    case RECEIVE_ANALYSIS_MESSAGE: // stacked position is checkmate, stalemate, or invalid
    case RECEIVE_STOCKFISH_MOVE: // received analysis from stockfish
      return initialState
    default:
      return state;
  }
}
