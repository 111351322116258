import { RECEIVE_STOCKFISH_MOVE } from '../constants/actionTypes';
/* global STOCKFISH */

export class StockfishWorker {
  constructor(dispatch) {
    this.stockfish = typeof STOCKFISH === "function" ? STOCKFISH() : new Worker("stockfish");
    this.fen = '';
    this.dispatch = dispatch;
    this.result = {};

    this.stockfish.onmessage = this.onMessage.bind(this);
  }

  onMessage(event) {
    const line = this.getLine(event);
    this.result.best_move = this.getBestMove(line, this.result.best_move);
    this.result.score = this.getScore(line, this.result.score);
    this.result.sequence = this.getSequence(line, this.result.sequence);

    if (this.result.best_move) {
      this.dispatch({
        type: RECEIVE_STOCKFISH_MOVE,
        fen: this.fen,
        best_move: this.result.best_move,
        score: this.result.score,
        sequence: this.result.sequence,
      });
    }
  }

  postMessage(message) {
    this.stockfish.postMessage(message);
  }

  getSequence(line, previous_sequence) {
    const match = line.match(/pv\s([a-h][1-8][a-h][1-8][qrbn]?(\s[a-h][1-8][a-h][1-8][qrbn]?)*)/);
    if (match) return match[1].split(' ');

    return previous_sequence;
  }

  getBestMove(line) {
    const match = line.match(/^bestmove ([a-h][1-8])([a-h][1-8])([qrbk])?/);

    if (match) {
      return { from: match[1], to: match[2], lan: match[1] + match[2], promotion: match[3] };
    }
  }

  getScore(line, previous_score) {
    const match = line.match(/^info .*\bscore (\w+) (-?\d+)/);
    if (match) {
      let score = parseInt(match[2]);
      if (match[1] === 'cp') {
        score = (score / 100.0).toFixed(2);
      } else if (match[1] === 'mate') {
        score = 'mate in ' + Math.abs(score);
      }
      return score;
    }
    return previous_score;
  }

  getLine(event) {
    // console.log(event)
    return event && typeof event === "string" ? event : '';
  }
}
