import { NUM_MOVES_TO_SHOW } from '../../constants/constants';
import { ANALYSIS_ACTIONS } from '../../constants/actionTypes';

const { RECEIVE_ANALYSIS_MOVE, RECEIVE_STOCKFISH_MOVE } = ANALYSIS_ACTIONS;

const initialState = {
  moves: [],
}

export function analysisArrowsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ANALYSIS_MOVE: // stacked
    case RECEIVE_STOCKFISH_MOVE: {
      const { sequence } = action;

      return { ...state, moves: sequence.slice(0, NUM_MOVES_TO_SHOW) };
    }
    default:
      return state;
  }
}
