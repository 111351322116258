import { ANALYSIS_ACTIONS } from '../../constants/actionTypes';

const { RECEIVE_POSITION } = ANALYSIS_ACTIONS;

const initialState = {
  moves: []
};

export function analysisPositionReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_POSITION:
      return action.position;
    default:
      return state;
  }
}