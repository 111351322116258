import React from 'react';
import PropTypes from 'prop-types';
/* global chessground */

class AnalysisResetButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { cgEventChange } = this.props;

    chessground.set({ fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR' });
    cgEventChange();
  }

  render() {
    return (
      <button type="button" className="btn btn-outline-info me-1" onClick={this.handleClick}>
        Reset
      </button>
    )
    }

}

AnalysisResetButton.propTypes = {
  cgEventChange: PropTypes.func.isRequired,
};

export default AnalysisResetButton
