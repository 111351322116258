import { ANALYSIS_ACTIONS } from '../../constants/actionTypes';

const { RECEIVE_STOCKFISH_MOVE } = ANALYSIS_ACTIONS;
const initialState = {
  fen: '',
  score: '0',
  sequence: [],
}

export function analysisUpdateReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_STOCKFISH_MOVE: {
      const { fen, score, sequence } = action;

      return { ...state, fen, score, sequence };
    }
    default:
      return state;
  }
}
