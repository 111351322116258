import { RECEIVE_CURRENT_USER } from '../actions/currentUser';

const initialState = {
  move_automatically: true,
}

function currentUserReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_CURRENT_USER:
      return action.currentUser;
    default:
      return state;
  }
}

export default currentUserReducer;
