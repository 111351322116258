import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* global CHESSJS chessground */

import { isEmpty } from '../util/utils';
import FeedbackModal from '../shared/FeedbackModal';
import ReportProblemFlag from '../shared/ReportProblemFlag';
import { NUM_MOVES_TO_SHOW } from '../constants/constants';
import { makeMoves } from '../util/chessHelper';

import { createOrUpdatePosition } from '../actions/position';
import { updateCastling } from '../actions/castling';
import { updateNavButtons } from '../actions/navButtons';
import AnalysisOpeningsButton from './AnalysisOpeningsButton';

// todo make this shared
function getColor(fen_id) {
  return fen_id.split(' ')[1];
}

const INSTRUCTION_HTML = (
  <div className="alert alert-primary" role="alert">
    Enter your position onto the board below, press White or Black to move.
    <strong className="text-success"> We will calculate the best move.</strong>
  </div>
);

const WAIT_HTML = (
  <div className="alert alert-info sticky-top" role="alert">
    Calculating, please wait...
  </div>
);

class AnalysisHeader extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.renderMoveButtons = this.renderMoveButtons.bind(this);
  }

  componentDidUpdate() {
    // when header updates, redraw chessground because the header
    // can push the board down the page when the message is long
    // which happens when there are sequence moves
    ['orientationchange', 'resize'].forEach(event => {
      window.addEventListener(event, chessground.redrawAll, false);
    });
  }

  handleClick = async (event) => {
    const { dispatch, fen, navButtons, position, sequence } = this.props;
    const numPendingMoves = Number(event.target.value);

    CHESSJS.load(fen); // sets up the board to the fen that was analyzed
    chessground.set({ fen: CHESSJS.fen() });

    const processedFens = await makeMoves(sequence.slice(0, numPendingMoves));
    const moves = position.moves.slice(0, navButtons.current + 1).concat(processedFens);

    dispatch(createOrUpdatePosition(position, { moves }));
    dispatch(updateCastling(chessground));
    dispatch(updateNavButtons(moves.length - 1));
  }

  renderMessage(divClass) {
    const { message } = this.props;

    return (
      <>
        <div className={"alert alert-" + divClass + " sticky-top"} role="alert">
          {message}
          <ReportProblemFlag />
        </div>
        <FeedbackModal />
      </>
    );
  }

  renderMoveButtons() {
    const { fen, openings, score, sequence } = this.props;
    const bestMove = this.renderFirstMoveButton(fen, sequence[0], 1);
    const nextMoves = this.renderNextMoveButtons(fen, sequence.slice(1, NUM_MOVES_TO_SHOW));

    return (
      <>
        <div className="alert alert-success sticky-top" role="alert">
          {bestMove}
          &nbsp;
          Score:
          &nbsp;
          {score}
          &nbsp;
          {nextMoves.length > 0 ? 'sequence' : ''}
          &nbsp;
          {nextMoves}
          &nbsp;
          <AnalysisOpeningsButton fen={fen} openings={openings} />
          <ReportProblemFlag />
        </div>
        <FeedbackModal />
      </>
    );
  }

  renderFirstMoveButton(fen, move, moveNum) {
    const btnClass = getColor(fen) === 'w' ? 'btn-light' : 'btn-dark';

    return (
      <>
        The best move is
        &nbsp;
        <button
          type="button"
          className={`btn btn-sm ${btnClass}`}
          value={moveNum}
          onClick={this.handleClick}
        >
          {move}
        </button>
      </>
    );
  }

  renderNextMoveButtons(fen, nextMoves) {
    let moveNum = 2;
    let btnClass = getColor(fen) === 'w' ? 'btn-dark' : 'btn-light';

    return nextMoves.map((move) => {
      const button = (
        <button
          key={move}
          type="button"
          className={`btn mr-2 btn-sm ${btnClass}`}
          value={moveNum}
          onClick={this.handleClick}
        >
          {move}
        </button>
      );
      // Toggle button class and increment move number for next button
      btnClass = btnClass === 'btn-light' ? 'btn-dark' : 'btn-light';
      moveNum++;
      return button;
    });
  }

  render() {
    const { status, sequence, message } = this.props;

    if (status === 'pending') return WAIT_HTML;
    if (status === 'error') return this.renderMessage('danger');
    if(message.length > 0) return this.renderMessage('info');
    if(!isEmpty(sequence)) return this.renderMoveButtons();
    if (status === 'idle') return INSTRUCTION_HTML;
  }
}

AnalysisHeader.propTypes = {
  dispatch: PropTypes.func.isRequired, // Function to dispatch actions
  fen: PropTypes.string.isRequired, // FEN string representing the current board state
  sequence: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of moves in sequence (e.g., SAN or UCI notation)
  score: PropTypes.string.isRequired, // Evaluation score of the position
  status: PropTypes.oneOf(['pending', 'error', 'idle']).isRequired, // Status of the analysis (e.g., pending, error, idle)
  message: PropTypes.string.isRequired, // A message string for displaying alerts or feedback
  openings: PropTypes.object,
  navButtons: PropTypes.shape({
    current: PropTypes.number
  }).isRequired, // Object representing the current position
  position: PropTypes.shape({
    moves: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of move strings in the position
  }).isRequired, // Object representing the current position
};

function mapStateToProps(state) {
  const { navButtons, position } = state;
  const { fen, openings, score, status, sequence, message } = state.analysisHeader;

  return { fen, navButtons, openings, position, score, status, sequence, message };
}

export default connect(mapStateToProps)(AnalysisHeader);
