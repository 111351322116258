import { ANALYSIS_ACTIONS } from '../../constants/actionTypes';
const { EDIT_FIELD, CHANGE_FEN_VALUE } = ANALYSIS_ACTIONS;
const initialState = {
  edit: false,
  newValue: null,
};

export function analysisFenInputReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_FIELD:
      return {
        ...state,
        edit: !state.edit,
      };
    case CHANGE_FEN_VALUE:
      return {
        ...state,
        newValue: action.newValue,
      };
    default:
      return state;
  }
}
