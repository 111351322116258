import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* global chessground */

import { createOrUpdatePosition } from '../actions/position';

class AnalysisFlipButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    chessground.toggleOrientation();

    const { dispatch, position } = this.props;

    const json = { orientation: chessground.state.orientation[0] }
    dispatch(createOrUpdatePosition(position, json))
  }

  render() {
    return (
      <button type="button" className="btn btn-outline-info me-1" onClick={this.handleClick}>
        Flip
      </button>
    );
  }
}

AnalysisFlipButton.propTypes = {
  position: PropTypes.shape({
    id: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { position } = state;

  return { position };
}

export default connect(mapStateToProps)(AnalysisFlipButton);
