import { SQUARES } from 'chess.js'
/* global CHESSJS chessground */

export function toDests(moves) {
  const dests = new Map();

  SQUARES.forEach((s) => {
    const ms = moves({ square: s, verbose: true });
    if (ms.length) dests.set(s, ms.map((m) => m.to));
  });

  return dests;
}

export function toColor(text) {
  return (text === 'w') ? 'white' : 'black';
}

export function isPromotion(chess) {
  return chess.history({ verbose: true }).slice(-1)[0].promotion !== undefined;
}

export function chessGameOver(chess) {
  return chess.game_over();
}

export function sanToMove(san, chess) {
  const move = chess.move(san);
  chess.undo();
  return move;
}

export function getSan(chess, fen, from, to) {
  chess.load(fen)
  return chess.move({ from, to })?.san
}

export function undoMove(chess, chessground) {
  chess.undo();
  chessground.set({
    fen: chess.fen(),
    turnColor: toColor(chess.turn()),
    movable: {
      color: toColor(chess.turn()),
      free: false,
      dests: toDests(chess.moves),
    },
  });
}

export function makeSanMoves(san_moves) {
  let last_move;

  san_moves.forEach((san_move, index) => {
    setTimeout(() => {
      last_move = CHESSJS.move(san_move);
      chessground.set({ fen: CHESSJS.fen(), lastMove: [last_move.from, last_move.to] })
    }, index * 500);
  });
}

export async function makeMoves(lan_moves) {
  const processedFens = [];

  for (const lan_move of lan_moves) {
    makeMove(lan_move, CHESSJS, chessground);
    const newFen = CHESSJS.fen().split(' ')[0];
    processedFens.push(newFen);
    await new Promise(resolve => setTimeout(resolve, 500)); // Set a delay before processing the next move
  }

  return processedFens;
}

// move is long algebraic e2e4, e7e5, etc...
export function makeMove(lan_move, chessjs, chessground) {
  let from, to, promotion;
  [from, to, promotion] = lan_move.match(/.{1,2}/g);
  chessjs.move({ from: from, to: to, promotion: promotion });
  chessground.set({ fen: chessjs.fen(), lastMove: [from, to] }); // lastmove highlights the from, to squares
}

export function gameResult(chess) {
  let result;
  if (chess.in_checkmate()) {
    result = chess.turn() === 'w' ? 'b' : 'w';
  } if (chess.in_stalemate()) {
    result = 'stalemate';
  } if (chess.in_draw()) {
    result = 'draw';
  } if (chess.in_threefold_repetition()) {
    result = 'draw_three';
  }

  return result;
}
