import { ANALYSIS_ACTIONS } from '../../constants/actionTypes';
const {
  RESET_ANALYSIS_HEADER,
  RECEIVE_ANALYSIS_MESSAGE,
  REQUEST_ANALYSIS,
  RECEIVE_ANALYSIS_MOVE,
  RECEIVE_STOCKFISH_MOVE,
} = ANALYSIS_ACTIONS;

const initialState = {
  fen: '',
  score: '0',
  status: 'idle', // pending, idle, error
  sequence: [],
  message: '',
}

export function analysisHeaderReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ANALYSIS:
      return { ...state, status: 'pending', message: ''};

    case RECEIVE_ANALYSIS_MOVE: // stacked
    case RECEIVE_STOCKFISH_MOVE: {
      const { fen, openings, score, sequence } = action;
      
      // openings is passthrough to AnalysisOpeningsButton
      return { ...state, fen, openings, score, sequence, status: 'idle', message: ''};
    }
    case RESET_ANALYSIS_HEADER:
      return initialState
    case RECEIVE_ANALYSIS_MESSAGE: {
      const { message, status } = action;

      return { ...state, message, status};
    }
    default:
      return state;
  }
}
